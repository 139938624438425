@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #111827;
}

::-webkit-scrollbar-thumb {
    background: #4a07da;
}
